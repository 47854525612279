<template>
  <header>
    <div
        v-if="isFixedHeader"
        :style="{height:headerHeight + 'px'}"
    />
    <div
      id="header"
      class="header-root"
      :class="{
        'fixed':isFixedHeader,
        'home': isHome
      }"
    >
      <div class="container">
        <div class="header-wrapper">
          <div class="logo">
            <NuxtLink to="/">
              <img
                src="~/assets/img/header/logo.svg"
                alt="logo"
              >
            </NuxtLink>
          </div>
          <nav
            class="menu"
          >
            <template
              v-for="(item, idx) in menu"
              :key="`nav-menu-${idx}`"
            >
              <nuxt-link
                v-if="item.isShow"
                :to="item.link !== '/#' ? item.link : `${item.link}${item.id}`"
              >
                {{ item.name }}
              </nuxt-link>
            </template>
          </nav>

          <div class="controls">
            <LangToggle
              :is-fixed-header="isFixedHeader"
            />

            <NuxtLink
              :to="localePath(linkForAuth)"
              class="control-btn"
            >
              <client-only :fallback="fallbackCabinetButton">
                {{ isLoggedIn ? $t('account.loggenIn') : $t('account.loggenOut') }}
              </client-only>
              <img
                src="/img/icons/icon-arrow-right-black.svg"
                alt="arrow-right"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useUserAuth } from '~/composables/user/useUserAuth';
import LangToggle from '~/components/widgets/language-toggle/LangToggle.vue';
import { useI18n } from '#imports';

const localePath = useLocalePath();

const { t, locale } = useI18n();

const { isLoggedIn } = useUserAuth();
const menu = computed(() => {
  return [
    {
      id: 'getSms',
      name: t('menu.getSms'),
      link: localePath('/#getSms'),
      isShow: true
    },
    {
      id: 'earnMoney',
      name: t('menu.earnMoney'),
      link: localePath('/#earnMoney'),
      isShow: true
    },
    {
      id: '',
      name: t('menu.doc'),
      link: localePath('/cabinet/api'),
      isShow: true
    },
    {
      id: '',
      name: t('menu.blog'),
      link: blogUrl.value,
      isShow: true
    }
  ];
});
const $route = useRoute();
const isFixedHeader = ref<boolean>(false);
const headerHeight = ref(135);

function handleScroll (event: Event) {
  headerHeight.value = document.getElementById('header')?.offsetHeight || 0;
  const isFixedCheck = ref(((event?.target as Document).scrollingElement?.scrollTop || 0) > 300);

  if (isFixedHeader.value !== isFixedCheck.value) {
    isFixedHeader.value = isFixedCheck.value;
  }
}

const isHome = computed((): boolean => $route.fullPath === '/' || $route.fullPath === '/ru' || $route.fullPath === '/#earnMoney' || $route.fullPath === '/#getSms');

const linkForAuth = computed(() => {
  return isLoggedIn ? '/cabinet' : '/authorization';
});

const fallbackCabinetButton = computed(() => {
  if (locale.value === 'en') {
    return 'Sign in'
  }
  if (locale.value === 'ru') {
    return 'Войти'
  }
})

const blogUrl = computed(() => {
  return locale.value === 'en' ? 'https://sms-code.ru/blog/' : 'https://sms-code.ru/blog/ru'
})

// Включить если хотим фиксированный хедер обратно
// onUnmounted(() => {
//   window.removeEventListener('scroll', handleScroll);
// });
// onMounted(() => {
//   window.addEventListener('scroll', handleScroll);
// });
</script>

<style scoped lang="scss">
header {
	width: 100%;
	z-index: 2;
}
.logo {
  transition: var(--transition);
  margin-top: -5px;
  &:hover {
    opacity: 0.8;
  }
}
.header-root {
	position: relative;
	left: 0;
	top: 0;
	right: 0;
	width: 100%;
	z-index: 999;
	transition: background-color .2s linear;
	background-color: var(--primary);

	&.home {
		position: absolute;
		background-color: transparent;
	}

	&.fixed {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		background-color: var(--primary);
		box-shadow: 0 5px 10px 0 rgb(46 145 235 / 5%);
		transition: top .4s;
		animation: header-opacity 0.8s;
	}
}

.header-wrapper {
	padding: 0 0 15px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& .menu {
		display: flex;
		gap: 46px;
		align-items: center;
		color: var(--white);
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 110%;
    padding-top: 20px;

		& > a {
			padding: 10px 0;
			transition: all .3s linear;
			text-decoration: underline;
			text-decoration-color: transparent;

			&:hover {
				text-decoration-color: initial;
			}
		}
	}

	& .controls {
		display: flex;
		gap: 16px;
    padding-top: 20px;
	}

	& .control-btn {
		min-width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 12px;
		font-size: 13px;
		line-height: 100%;
		font-weight: 500;
		border-radius: 30px;
		background: rgba(255, 255, 255, 0.24);
		cursor: pointer;
    gap: 5px;

    &:hover {
      text-decoration: underline;
    }
	}
}

@media screen and (max-width: 1024px) {
	.header-wrapper {
		.menu {
			gap: 20px;
		}

		.logo {
			width: 150px;
		}
	}
}
</style>
